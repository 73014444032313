import React from 'react'
import { List } from 'immutable'
import Layout from '../../components/Layouts/Layout'
import SEO from '../../components/SEO'
import { Variation, EmptyVariation, VariationsInput } from '../../components/EmailABTestCalculator'
import { UnderConstruction } from '../../components/UnderConstruction'
import { VerticalSpace } from '../../components/VerticalSpace'
import { Percentage } from '../../components/Percentage'
import { getProbabilityVariationsEqual } from 'statistics/src/getStatisitcalSignificance'

type Key = Exclude<keyof Variation, 'label'>
interface KeyPair {
  title: string
  conversionsKey: Key
  populationKey: Key
}
const keyPairs: KeyPair[] = [
  { title: 'Deliveries per send', conversionsKey: 'delivered', populationKey: 'sent' },

  { title: 'Opens per delivery', conversionsKey: 'opened', populationKey: 'delivered' },

  { title: 'Clicks per delivery ', conversionsKey: 'clicked', populationKey: 'delivered' },
  { title: 'Clicks to opens', conversionsKey: 'clicked', populationKey: 'opened' },

  { title: 'Conversions per delivery', conversionsKey: 'converted', populationKey: 'delivered' },
  { title: 'Conversions per open', conversionsKey: 'converted', populationKey: 'opened' },
  { title: 'Conversions per click', conversionsKey: 'converted', populationKey: 'clicked' },

  { title: 'Unsubscribes per delivery', conversionsKey: 'unsubscribed', populationKey: 'delivered' },
]

const EmailABTestCalculator: React.FunctionComponent = () => {
  const [variations, setVariations] = React.useState<List<Variation>>(List.of(EmptyVariation, EmptyVariation))

  return (
    <Layout title="Email A/B Test Calculator" className="pb-5">
      <SEO
        title="Email AB Test Calculator"
        description="calculator for determining results of email ab tests"
      />
      <VerticalSpace height={50} />

      <VariationsInput
        variations={variations}
        setVariations={setVariations}
      />
      <VerticalSpace height={75} />
      {variations.flatMap((variationA, x) => variations.slice(x + 1).map((variationB, y) => (
        <Comparison variationA={variationA} variationB={variationB} key={`${x}-${y}`} />
      )))}
    </Layout>

  )
}

export default EmailABTestCalculator

const threshold = 0.95

interface ComparisonProps {
  variationA: Variation
  variationB: Variation
}

const Comparison: React.FunctionComponent<ComparisonProps> = ({ variationA, variationB }) => {
  const results = React.useMemo(() => keyPairs.map((keyPair) => {
    const conversionsA = variationA[keyPair.conversionsKey]
    const populationA = variationA[keyPair.populationKey]
    const conversionRateA = conversionsA / populationA

    const conversionsB = variationB[keyPair.conversionsKey]
    const populationB = variationB[keyPair.populationKey]
    const conversionRateB = conversionsB / populationB

    const [winner, loser, improvement] = (
      conversionRateA > conversionRateB ?
        [variationA.label, variationB.label, conversionRateA / conversionRateB] :
        [variationB.label, variationA.label, conversionRateB / conversionRateA]
    )
    const statisticalSignificance = 1 - getProbabilityVariationsEqual(
      { conversions: conversionsA, population: populationA },
      { conversions: conversionsB, population: populationB }
    )

    return {
      title: keyPair.title,
      winner,
      loser,
      improvement,
      statisticalSignificance
    }
  }), [variationA, variationB])

  return (
    <React.Fragment>

      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title text-center">
            {variationA.label} vs {variationB.label}
          </h3>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Metric</th>
                <th>Winner</th>
                <th>Loser</th>
                <th>Improvement</th>
                <th>Confidence</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result) => (
                <tr className={result.statisticalSignificance >= threshold ? 'text-primary' : 'text-muted'}>
                  <th>{result.title}</th>
                  <td>{result.winner}</td>
                  <td>{result.loser}</td>
                  <td><Percentage value={result.improvement - 1} /></td>
                  <td><Percentage value={result.statisticalSignificance} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <VerticalSpace height={50} />

    </React.Fragment>
  )
}
