import React from 'react'
import { List } from 'immutable'
import { NumberInput, TextInput } from '../Input'
import './style.scss'
import { Percentage } from '../Percentage'
import { Label } from "../Label"

export interface Variation {
  label: string
  sent: number
  delivered: number
  opened: number
  clicked: number
  converted: number
  unsubscribed: number
}

export const EmptyVariation: Variation = {
  label: "Unnamed",
  sent: 0,
  delivered: 0,
  opened: 0,
  clicked: 0,
  converted: 0,
  unsubscribed: 0,
}

interface VariationInputProps {
  variation: Variation
  setVariation: (formData: VariationInputProps['variation']) => void
}

export const VariationInput: React.FunctionComponent<VariationInputProps> = ({ variation, setVariation }) => {
  const onLabelChange = React.useCallback((value) => {
    setVariation({
      ...variation,
      label: value,
    })
  }, [variation, setVariation])

  const makeOnChange = (key: keyof VariationInputProps['variation']) => (value: number) => {
    setVariation({
      ...variation,
      [key]: value,
    })
  }

  return (
    <React.Fragment>
      <div className="form-group">
        <Label>Variant name</Label>
        <TextInput
          value={variation.label}
          onChange={onLabelChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <Label>Sent</Label>
        <NumberInput
          value={variation.sent}
          onChange={makeOnChange('sent')}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <Label>Delivered</Label>
        <NumberInput
          value={variation.delivered}
          onChange={makeOnChange('delivered')}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <Label>Opened</Label>
        <NumberInput
          value={variation.opened}
          onChange={makeOnChange('opened')}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <Label>Clicked</Label>
        <NumberInput
          value={variation.clicked}
          onChange={makeOnChange('clicked')}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <Label>Converted</Label>
        <NumberInput
          value={variation.converted}
          onChange={makeOnChange('converted')}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <Label>Unsubscribed</Label>
        <NumberInput
          value={variation.unsubscribed}
          onChange={makeOnChange('unsubscribed')}
          className="form-control"
        />
      </div>
    </React.Fragment>
  )
}

interface VariationsInputProps {
  variations: List<Variation>
  setVariations: (formData: VariationsInputProps['variations']) => void
}

export const VariationsInput: React.FunctionComponent<VariationsInputProps> = ({ variations, setVariations }) => {
  const makeOnChange = (index: number) => (value: Variation) => {
    setVariations(variations.set(index, value))
  }

  const addVariation = React.useCallback(() => {
    setVariations(variations.push(EmptyVariation))
  }, [variations, setVariations])

  const makeRemoveVariation = (index: number) => () => {
    setVariations(variations.remove(index))
  }

  return (
    <React.Fragment>
      <div className="row">
        {variations.map((variation, i) => (
          <div className="col-lg-4 col-md-6" key={i}>
            <div className="card">
              <div className="card-body variation">
                <VariationInput variation={variation} setVariation={makeOnChange(i)} />
                <VariationRates variation={variation} />
                <button type="button" onClick={makeRemoveVariation(i)} className="btn btn-quarternary">
                  Remove variant
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5 text-center">
        <button type="button" onClick={addVariation} className="btn btn-secondary">
          Add another variant
      </button>
      </div>
    </React.Fragment>
  )
}

const calculateRates = (variation: Variation) => {
  const deliveriesPerSent = variation.delivered / variation.sent

  const opensPerSent = variation.opened / variation.sent
  const opensPerDelivered = variation.opened / variation.delivered

  const clicksPerSent = variation.clicked / variation.sent
  const clicksPerDelivered = variation.clicked / variation.delivered
  const clicksPerOpen = variation.clicked / variation.opened

  const conversionsPerSent = variation.converted / variation.sent
  const conversionsPerDelivered = variation.converted / variation.delivered
  const conversionsPerOpened = variation.converted / variation.opened
  const conversionsPerClicked = variation.converted / variation.clicked

  const unsubscribesPerDelivered = variation.unsubscribed / variation.delivered

  return {
    deliveriesPerSent,
    opensPerSent,
    opensPerDelivered,
    clicksPerSent,
    clicksPerDelivered,
    clicksPerOpen,
    conversionsPerSent,
    conversionsPerDelivered,
    conversionsPerOpened,
    conversionsPerClicked,
    unsubscribesPerDelivered,
  }
}

interface VariationRatesProps {
  variation: Variation
}

export const VariationRates: React.FunctionComponent<VariationRatesProps> = ({ variation }) => {
  const rates = React.useMemo(() => calculateRates(variation), [variation])

  return (
    <React.Fragment>
      <div className="mt-3">
        <div className="mb-3">
          <div>Deliveries per send: <Percentage value={rates.deliveriesPerSent} /></div>
        </div>
        <div className="mb-3">
          <div>Opens per delivered: <Percentage value={rates.opensPerDelivered} /></div>
        </div>
        <div className="mb-3">
          <div>Clicks per delivered: <Percentage value={rates.clicksPerDelivered} /></div>
          <div>Clicks per opened: <Percentage value={rates.clicksPerOpen} /></div>
        </div>
        <div className="mb-3">
          <div>Conversions per delivered: <Percentage value={rates.conversionsPerDelivered} /></div>
          <div>Conversions per opened: <Percentage value={rates.conversionsPerOpened} /></div>
          <div>Conversions per clicked: <Percentage value={rates.conversionsPerClicked} /></div>
        </div>
        <div className="mb-3">
          <div>Unsubscribes per delivered: <Percentage value={rates.unsubscribesPerDelivered} /></div>
        </div>
      </div>
    </React.Fragment >
  )
}
