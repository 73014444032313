import { NormalDistribution } from "./NormalDistribution"

interface Variation {
  conversions: number
  population: number
}

function addVariations(a: Variation, b: Variation): Variation {
  return {
    conversions: a.conversions + b.conversions,
    population: a.population + b.population
  }
}

export function getProbabilityVariationsEqual(a: Variation, b: Variation) {
  const conversionRateA = a.conversions / a.population
  const conversionRateB = b.conversions / b.population
  const conversionRateDelta = conversionRateA - conversionRateB
  const variationTotal = addVariations(a, b)
  const conversionRateTotal = variationTotal.conversions / variationTotal.population
  const tmp = conversionRateTotal * (1 - conversionRateTotal)
  const variance = tmp / a.population + tmp / b.population
  const nullHypothesis = NormalDistribution(0, variance)
  const zScore = nullHypothesis.getStandardScore(conversionRateDelta)
  let probability = nullHypothesis.getCumulativeProbability(zScore)
  if (zScore > 0) {
    probability = 1 - probability
  }
  return 2 * probability
}
